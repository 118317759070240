import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
import researchGraphic from '../images/teleworker-career-satisfaction.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Thinking aloud about working remotely`}</h1>
    <p>{`I just finished reading a great book on a modern alternative to the status quo of working for a living. `}<em parentName="p">{`Remote: Office Not Required`}</em>{` by David Heinemeier Hansson and Jason Fried dives into the benefits and drawbacks of working remotely. This text has inspired me to distill some thoughts on the subject.`}</p>
    <h2><HashLink id="quality-of-life" to="/remote-work#quality-of-life" mdxType="HashLink">{`Quality of life`}</HashLink></h2>
    <p>{`Employees stand to benefit from working away from the office.`}</p>
    <p>{`In my opinion, the location-agnostic aspect of being able to work from outside the office is a huge quality of life improvement. Having the opportunity for nomadic freedom before retirement is a lifelong dream of many people in this world. `}</p>
    <p>{`The inherent flexibility of working anywhere `}<em parentName="p">{`but`}</em>{` the office enables you to take it to any extreme. Even if you don't travel to new countries every month you would still have the freedom for change of scenery on a smaller scale. Want to work from your favorite local coffee shop one day? Go for it. Maybe you'd prefer a hybrid approach where you start your day from home and work uninterrupted until after lunch where you go into the office to collaborate with coworkers. There is a ton of value in switching things up. Not to mention strict routine has a tendency to diminish creativity. Forget the days of getting in the car at the same time in the morning, driving to work, sitting in the same chair all day, counting the minutes until 5 o'clock, driving (crawling) back home through rush hour traffic and repeating this until the weekend rolls around.`}</p>
    <p>{`Believe it or not there are companies out there that will provide equal pay for equal work regardless of location. This can facilitate the end of the city monopoly found in places like New York and San Francisco. Imagine a world where the new corporate luxury is to be paid fairly for the work you deliver and live wherever you want regardless of company location rather than the stereotypical plush corner office and company Lexus. `}</p>
    <p>{`The book also mentions health risks related to commuting to work everyday. I hadn't heard much of this before so I decided to do some digging. The statement seems to be fairly accurate for those who commute to work everyday in their vehicle. For example, a `}<a parentName="p" {...{
        "href": "https://www.sciencedirect.com/science/article/abs/pii/S0749379712001675"
      }}>{`scholarly article published by the American Journal of Preventive Medicine`}</a>{` in 2012 concluded the following.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Commuting distance was adversely associated with physical activity, `}{`[cardiorespiratory fitness]`}{`, adiposity, and indicators of metabolic risk.`}</p>
    </blockquote>
    <p>{`Commuting less is noteworthy when you consider this information coupled with the potential time savings.`}</p>
    <h2><HashLink id="productivity" to="/remote-work#productivity" mdxType="HashLink">{`Productivity for fun and profit`}</HashLink></h2>
    <p>{`In addition to employee satisfaction, there are strong incentives for both employees `}<em parentName="p">{`and`}</em>{` business leaders to support remote working arrangements. A significant example is `}<strong parentName="p">{`productivity`}</strong>{`.`}</p>
    <h3><HashLink id="distraction" to="/remote-work#distraction" mdxType="HashLink">{`Distraction`}</HashLink></h3>
    <p>{`The typical office setting tends to be an environment conducive to distraction. This is not to say people can't get distracted at home or wherever they decide to work remotely. Are there less distractions when working remotely than in the office? It depends on the person. Some folks have trouble focusing in the comfort of their own home. A lot of tips people talk about for mitigating this risk revolve around establishing boundaries.`}</p>
    <ul>
      <li parentName="ul">{`If possible, work in a designated location for work like a home office.`}</li>
      <li parentName="ul">{`Dress presentably when working.`}</li>
      <li parentName="ul">{`Set expectations with family members and friends.`}</li>
    </ul>
    <p>{`Sometimes remote distraction can be a good thing. It can be a sign that you need a break or that the work you're doing isn't fulfilling. Frequent distractions due to the latter can serve as a good indication that you may need to look elsewhere for work.`}</p>
    <p>{`All in all, it's important that remote distraction is lower than in-office distraction while you're in "work mode".`}</p>
    <h3><HashLink id="life-work-balance" to="/remote-work#life-work-balance" mdxType="HashLink">{`Life-work balance`}</HashLink></h3>
    <p>{`Asynchronous collaboration breeds healthier life-work balance. Personally I find that I write better code in a more efficient manner when working asynchronously throughout the day in blocks of time with complete focus. While in the office, I tend to take walks in between these blocks of time. `}</p>
    <p>{`Some people swear by practices such as the Pomodoro Technique`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`, however, for me the duration of these time blocks varies. I'll usually take a break after reaching a checkpoint where my cognitive load is low and I have some sort of deliverable to comfortably build upon in the future. `}</p>
    <p>{`Additionally, breaks work very well for me when I get stuck on something. More often than not I figure a solution relatively shortly after returning to the issue.`}</p>
    <h3><HashLink id="office-productivity" to="/remote-work#office-productivity" mdxType="HashLink">{`Productive time in office`}</HashLink></h3>
    <p>{`Early in my career I worked at a large corporation where sometimes I felt like I would get more accomplished in day than some folks would do in the week. Hopefully this doesn't come off as a complaint or vanity metric because it's simply an observation. Some of my peers would pass the time by surfing Reddit, hanging out with others at the company cafe or scheduling meetings to stay busy. I've observed the unimportance of "working" in an office for a certain amount of time per day. `}</p>
    <p>{`Unfortunately, the 9 to 5 paradigm can also have an impact on the evaluation of employee performance. This metric is antiquated at best. Business leaders should use the work produced by an employee to evaluate performance rather than ass-in-the-seat time. Analyzing the amount of work delivered can help identify workers doing the bare minimum to keep their job as well as those who're struggling in need of training or alternative work.`}</p>
    <h3><HashLink id="$$$" to="/remote-work#$$$" mdxType="HashLink">{`$$$`}</HashLink></h3>
    <p>{`Elevate the company talent pool by removing a prospective employee's locality as a barrier to entry and reap the benefits. Capture more global markets with boots on the ground. Simple things get overlooked when building software like an example mentioned in `}<em parentName="p">{`Remote: Office Not Required`}</em>{` where calendar weeks can start on Monday vs Sunday depending on where the user is located. People in remote areas can easily catch issues like this and offer valuable diversity of thought to your organization.`}</p>
    <p>{`The book also argues that allowing employees to telecommute can save the company money. A lot of money. The authors cite an IBM case study where downsizing office space was very lucrative for the tech giant. After some googling I found the claim that, "More than 58 million square feet of office space had been unloaded, at a gain of nearly $2 billion"`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{`. `}</p>
    <p>{`Business owners aren't the only ones benefiting from the cost savings. Remote employees save on the cost of commuting which, in turn, reduces carbon emissions for more savings.`}</p>
    <h2><HashLink id="the-office" to="/remote-work#the-office" mdxType="HashLink">{`The office`}</HashLink></h2>
    <p>{`IBM ended up calling many employees back to the office in 2017 which resulted in a slew of media. This came just 3 years after publishing a `}<a parentName="p" {...{
        "href": "https://www.ibm.com/downloads/cas/O90WYGXZ"
      }}>{`technical white paper`}</a>{` with graphics like the one pictured below.`}</p>
    <img alt="Teleworker career advancement is satisfactory" src={researchGraphic} width="100%" />
    <p>{`What's not completely clear to me is `}<em parentName="p">{`why`}</em>{` IBM decided to recall employees to offices. After reading a handful of articles it seems like training folks on agile methodologies involved documents with hundreds of pages and wasn't as productive as being face-to-face. There has been a ping pong game of research done with results for and against telecommuting. `}</p>
    <p>{`To me, it seems like there are some companies that throw their hands up without exploring all avenues of creating a successful remote working organization. For example, all tech choices aside, one remote person on a team usually isn’t the best idea for pitching remote working arrangements. It's easy for local and remote parties to feel alienated this way and it might draw false conclusions about the value of telecommuting. On the other hand, this way of working simply just isn't for everybody. There are industries where centralizing employees is crucial to the business.`}</p>
    <p>{`All of this turmoil centers around `}<strong parentName="p">{`the office`}</strong>{`. Is it good or bad? My answer is it depends what rules are enforced.`}</p>
    <ul>
      <li parentName="ul">{`If working remotely is strictly forbidden an office can be a single point of failure when power goes out, there is inclement weather, an employee catches a cold or has plumbing issues, etc. There are no facilities in place for employees that can't make it in to the office to work from home.`}</li>
      <li parentName="ul">{`In my experience, ergonomic comfort is extremely important to health and productivity at work. Sometimes an ergonomic setup may be more flexible at home than in the office.`}</li>
      <li parentName="ul">{`People already act as if they’re remote while in the office. I can't count the number of times I've emailed or instant messaged a coworker one cubical apart from me. A lot of social cohesion comes via technology like Slack regardless of the location of coworkers.`}</li>
      <li parentName="ul">{`Offices can be great for things like collaboration, impressing prospective clients and getting the company together a few times a year for crucial relationship building.`}</li>
    </ul>
    <h2><HashLink id="deal-breakers" to="/remote-work#deal-breakers" mdxType="HashLink">{`Deal breakers`}</HashLink></h2>
    <p>{`A successful remote working arrangement is a two-way street. Business leaders need to empower workers to make decisions with as little red tape to cut through as possible. Autonomous teams tend to deliver quality work efficiently and take pride in the impact they make for the organization.`}</p>
    <p>{`Access to information = productivity. Crucial (even sensitive) information and capability should be open to everyone. Trust your employees to get the job done without the layers of access requests and managerial approval. At one of my old workplaces it was someone's `}<strong parentName="p">{`full time job`}</strong>{` to grant access to SharePoint content. A request would be submitted to a queue (only after manager approval) and one person with elevated privilege would need to manually grant permission to the desired content. Sometimes this process would take 2 business days to complete. Flash forward 2 years and the company rolls out Microsoft Teams. Anybody can spin up a Team and have full control of the underlying SharePoint site. Permissions and all. How much time and money was wasted for `}<strong parentName="p">{`years`}</strong>{`? I kind of don't want to know.`}</p>
    <p>{`These deal breakers are keys to how successful startups operate and how many of the existing tech giants got to be where they are. It seems the importance of these principles should be valued in an organization whether or not employees are telecommuting. But I can't imagine remote working arrangements reaching their potential without them.`}</p>
    <h2><HashLink id="challenges" to="/remote-work#challenges" mdxType="HashLink">{`Challenges`}</HashLink></h2>
    <p>{`Let's roll directly from the prerequisites to some of the biggest challenges of telecommuting.`}</p>
    <p>{`It takes personal commitment to establish an alternative work regimen. This is not to say that people can't slack off in the office too but discipline is especially important when you're in complete control of your workday. Lines can easily become blurred between life and work. Slacking shouldn't be the only consideration here. Be on the lookout for overworking yourself, as well. `}</p>
    <p>{`Telecommuting can also enable people to exercise less. It's important to be cognizant of this and take steps to stay active.`}</p>
    <p>{`Another issue to be aware of is some of the ambiguity that comes with communicating via text. Tone of voice and personality can be lost when emailing or instant messaging people. Make sure to keep this in mind.`}</p>
    <p>{`People always worry about security (for good reason) but it’s pretty much already a solved problem. Remote workers at 37signals - now Basecamp - follow this baseline:`}</p>
    <ul>
      <li parentName="ul">{`All employees must use hard drive encryption`}</li>
      <li parentName="ul">{`Required password for computer`}</li>
      <li parentName="ul">{`Always use HTTPS`}</li>
      <li parentName="ul">{`Make sure each device can be wiped remotely`}</li>
      <li parentName="ul">{`Use a unique password for each site and store them in a password manager`}</li>
      <li parentName="ul">{`Turn on 2FA for things like email `}</li>
    </ul>
    <p>{`Last but not least - sometimes nothing beats a face-to-face meeting with your manager. However, it's worth noting that too many of these reduce the value in each meeting. This speaks to the importance of having an office to meet in.`}</p>
    <h2><HashLink id="overall-thoughts" to="/remote-work#overall-thoughts" mdxType="HashLink">{`Overall thoughts`}</HashLink></h2>
    <p>{`With any anthropological blanket statement comes inaccuracy in the form of some edge case or another. Not all companies run like the stereotypical corporation that comes to mind in many of these thoughts. Much of my reflection on this book is through the biased lens of a software engineer. I definitely recognize that there are still many industries where this is tough to pull off like health care, construction, etc.`}</p>
    <p>{`While remote work alleviates a lot of the issues with traditional office settings, telecommuting doesn't come without challenges. I don't believe every person is capable of working remotely or would even want to. However, it's important that remote companies effectively train their employees on working away from the office. Research technology to facilitate this culture and give it a fair shake.`}</p>
    <p>{`That being said, a lot of the ideas and facts mentioned in this book resonate with me due to my personal experience throughout my career. We've seen telecommuting work at scale with large corporations as well as the open source software community. Not only was this book a fascinating read but it was a great sanity check for me to validate a lot of what I've concluded by experiencing the impact of remote working arrangements. I hope more people get to witness life-work balance in it's best form by working remotely.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`"The `}<strong parentName="li">{`Pomodoro Technique`}</strong>{` is a time management method developed by Francesco Cirillo in the late 1980s. The technique uses a timer to break down work into intervals, traditionally 25 minutes in length, separated by short breaks." - `}<em parentName="li">{`Wikipedia`}</em><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}><a parentName="li" {...{
            "href": "https://www.theatlantic.com/magazine/archive/2017/11/when-working-from-home-doesnt-work/540660/"
          }}>{`When Working From Home Doesn’t Work`}</a>{` `}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      